<template>
  <section class="shop-container text-center">
    <div class="text-h2">Аяллын номнууд</div>
    <!-- <h2 class="section-title font-weight-medium"> -->
    <div class="app-section-subtitle">
      Шилмэл, шилдэг номнууд таны аяллын сэтгэлийг баясгана
    </div>
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          md="2"
          lg="2"
          sm="6"
          v-for="(book, bindex) in books"
          :key="bindex"
        >
          <v-card flat color="transparent">
            <v-img :src="book.image"></v-img>
            <div class="text-h4 text-center">{{ book.name }}</div>
            <div class="app-subtitle text-center">{{ book.price }}</div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
export default {
  data() {
    return {
      books: [
        {
          name: "МОНГОЛЫН НУУЦ ТОВЧООН",
          price: "29,900₮",
          image: "book1.png",
          url: "http://www.monsudar.mn/books/view/589/",
        },
        {
          name: "ХАЙРХААН",
          price: "20,000₮",
          image: "book2.png",
          url: "https://www.internom.mn/%D0%BD%D0%BE%D0%BC/%D1%85%D0%B0%D0%B9%D1%80%D1%85%D0%B0%D0%B0%D0%BD",
        },
        {
          name: "BIRDS OF MONGOLIA",
          price: "60,900₮",
          image: "./books/book3.png",
          url: "http://www.monsudar.mn/books/view/589/",
        },
        {
          name: "INTO WILD MONGOLIA",
          price: "99,900₮",
          image: "./books/book4.png",
          url: "https://www.amazon.com/Into-Wild-Mongolia-George-Schaller/dp/030024617X",
        },
        {
          name: "Men and Gods in Mongolia",
          price: "65,900₮",
          image: "./books/book5.png",
          url: "https://www.amazon.com/Men-Gods-Mongolia-Henning-Haslund/dp/1939149436/ref=sr_1_14?crid=O94BUJJYM86W&keywords=Mongolia&qid=1646551549&s=books&sprefix=mongoli%2Cstripbooks-intl-ship%2C346&sr=1-14",
        },
      ],
    };
  },
};
</script>
<style>
.feature3-card {
  position: absolute;
  top: 17%;
  right: 0;
}
.feature3-card .feature3-title {
  font-size: 24px;
  line-height: 30px;
  margin: 20px 0 15px;
  color: #3e4555 !important;
}
</style>